import React from 'react';
import { constants, useTranslation } from 'cng-web-lib'

import Namespace from "../../../constants/locale/Namespace";
import TmpCompanyProfileKeys from 'src/constants/locale/key/TmpCompanyProfile'

const NAMESPACE = Namespace.TMP_COMPANY_PROFILE;

const {
    locale: {
        key: {
            UiComponentKeys,
        },
    },
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_TITLE);

    const name = translate(NAMESPACE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_NAME)
    const registerNo = translate(NAMESPACE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_REG_NO)
    const referenceNo = translate(NAMESPACE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_REF_NO)
    const address = translate(NAMESPACE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_ADDRESS)

    let tmpCompanyProfile = translate(NAMESPACE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_TITLE)


    return {
        moduleTitle,
        name,
        registerNo,
        referenceNo,
        address,
        tmpCompanyProfile
    }
}

export default TranslationText;


