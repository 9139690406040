import React, { useState, createContext, useEffect, useMemo } from 'react'
import { Box, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { components } from 'cng-web-lib'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import TranslationText from './TranslationText'
import AddPageDialog from 'src/views/tmp-admin/supplier/AddPageDialog.js'
import { useHistory } from 'react-router-dom'

const { button: { CngPrimaryButton, CngButton }, CngDialog } = components
const DialogContext = createContext(null)

function CreateSupplierDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
}) {

  const [isSubmitting] = useState(false)
  const [isSubmitSuccess, setSubmitSuccess] = useState(false)
  const translatedTextsObject = TranslationText()
  const history = useHistory()

  useEffect(() => {
  }, [isSubmitSuccess])

  const providerValue = useMemo(() => ({ closeDialog }), []);

  return (
    <DialogContext.Provider
      value={providerValue}
    >
      <CngDialog
        customDialogContent={
          <Box mx={2} my={1}><Grid container spacing={2} alignItems="center"><Grid item xs>
            <div style={isSubmitSuccess ? { display: 'none' } : {}}>
              <AddPageDialog
                showNotification={showNotification}
                history={history}
                onPostSubmitSuccess={() => {
                  setSubmitSuccess(true)
                }}
              /></div>

            <div style={isSubmitSuccess ? {} : { display: 'none' }}>
              <Alert severity="success">
                Success: Supplier has been created.
              </Alert>

              <div>
                <br />
                <CngPrimaryButton color="secondary"
                  onClick={() => {
                    closeDialog(false)
                    setSubmitSuccess(false)
                  }}
                  disabled={isSubmitting}  >
                  Close
                </CngPrimaryButton>
              </div>
            </div>
          </Grid></Grid></Box>
        }
        dialogTitle={<>
          <b>{translatedTextsObject.createSupplier}</b>
          <CloseDialogIconButton
            onClick={() => {
              closeDialog(false)
            }}
            disabled={isSubmitting}
          />
        </>}

        open={isDialogOpen}
        fullWidth
        maxWidth='lg'

      />
    </DialogContext.Provider >
  )
}

export default CreateSupplierDialog
