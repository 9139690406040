import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import TmpCountryMapKey from 'src/constants/locale/key/TmpCountryMap'
import BreadcrumbNameMap from '../../../navigations/breadcrumbs/Breadcrumb_TmpADMIN'
import TablePage from './TablePage'


//* Module Title
function ModuleTitle() {
    const { translate } = useTranslation(Namespace.TMP_COUNTRY_MAP)
    let title = translate(Namespace.TMP_COUNTRY_MAP, TmpCountryMapKey.TMP_COUNTRY_MAP)

    return (title);
}

//* Wrapped Table Page
function WrappedTablePage(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <TablePage showNotification = {showSnackbar} {...props}/>
        )}
            extraCngBreadcrumbNameMap = {breadcrumbNameMap}
            ModuleTitle={ModuleTitle()}
        />
    )
}



export {
    WrappedTablePage as TablePage
}