import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  DataFlattener
} from 'cng-web-lib'

import TmpCompanyProfileApiUrls from 'src/apiUrls/TmpCompanyProfileApiUrls'
import React, { useRef } from 'react'
import TranslationText from './TranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup

  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
} = constants

function TablePage(props,
  orderBy,
  orderDirection) {
  console.log('table page')
  const {
    location: { pathname },
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const translatedTextsObject = TranslationText();
  const cngTableRef = useRef()

  const columns = [
    {
      field: "name",
      title: translatedTextsObject.name,
      defaultSort: 'asc'
    },
    {
      field: "registerNo",
      title: translatedTextsObject.registerNo,
      filtering: false
    },
    {
      field: "referenceNo",
      title: translatedTextsObject.referenceNo,
      filtering: false
    },
    {
      field: "address",
      title: translatedTextsObject.address,
      filtering: false
    }
  ]


  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              idAccessor="id"
              notification={notification}
              addRoute={`${pathname}/add`}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              del={{ url: TmpCompanyProfileApiUrls.DELETE }}
              fetch={{ url: TmpCompanyProfileApiUrls.GET }}
              cngTaviewRoutebleRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

function toServerDataFormat(localData) {
  const reqData = {
    "data": [{
      "name": localData.name,
      "registerNo": localData.registerNo,
      "referenceNo": localData.referenceNo,
      "address": localData.address,
    }]
  }
  return DataFlattener.unflatten(reqData);
}

export default TablePage
