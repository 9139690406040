import { Yup, constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants
const today = new Date();

// const dateTransformer = (value: string): string => {
//   return dayjs(value).format('YYYY-MM-DD');
// };

today.setHours(0, 0, 0, 0);
function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )


  return Yup.object(
    {
      componentNumber: Yup.string().required(requiredMessage),
      componentName: Yup.string().required(requiredMessage),
      customTariffNumber: Yup.string().matches(/^\d{6}\d{0,6}[a-zA-Z0-9]?$/, 'Tariff number should length min 6 to max 13. Alphabet allow in last char after 6 digit only').required(requiredMessage),
      ltsd: Yup.boolean(),
      ltsdStartDate: Yup.string()
        .when('ltsd', (ltsd) => {
          if (ltsd) {
            return Yup.string().required('Required').nullable();
          }
        }),
      ltsdEndDate: Yup.string()
        .when('ltsd', (ltsd) => {
          if (ltsd) {
            return Yup.string().required('Required').nullable();
          }
        })
        .when('ltsdStartDate', (ltsdStartDate) => {
          if (ltsdStartDate) {
            return Yup.date().min(ltsdStartDate, 'To Date has to be before Start Date');
          }
        }),
      supplierManufactureName: Yup.string()
        .when('ltsd', (ltsd) => {
          if (ltsd) {
            return Yup.string().required('Required').nullable();
          }
        })
        .nullable()
    })
}

export default makeValidationSchema
