import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'

import TmpCountryApiUrls from '../../../apiUrls/tmp-admin/TmpCountryApiUrls'


const {
    table: { CngCrudTable, useDefaultNotification }
} = components
const { locale: {key: {UiComponentKeys}}} = constants;

function TablePage(props) {
    const { location: showNotification} = props
    const notification = useDefaultNotification(showNotification)
    
    const translatedTextsObject = makeTranslatedTextsObject();

    function makeTranslatedTextsObject() {

        

        //columns
        let exportCountry = 'Export Country'
        let importCountry = 'Import Country'
        let ftaCode = 'FTA Code'

        return {
            exportCountry,
            importCountry,
            ftaCode
        }
    }

        const columns = [
            { field: 'exportCountryName', title: translatedTextsObject.exportCountry, filtering: false },
            { field: 'importCountryName', title: translatedTextsObject.importCountry, filtering: false },
            { field: 'ftaCode', title: translatedTextsObject.ftaCode }
        ];

        //return JSX
        return (
            <Card>
                {/* <CngSimpleCardHeader title={translatedTextsObject.tableTitle}/> */}
                <Divider/>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{wordBreak: "break-word"}}>
                            <CngCrudTable {...props}
                            notification = {notification}

                            //table fetch and data details
                            fieldLevel = 'toBeChangedIfWantFieldLevel'
                            fetch = {{ url: TmpCountryApiUrls.GET }}
                            idAccessor = 'id'
                            columns = {columns}
                            del={{ url: TmpCountryApiUrls.DELETE }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            //END JSX
        );
}

export default TablePage