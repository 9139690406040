import React, { useState, useEffect } from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, constants, useServices } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import TmpCodeMasterApiUrls from 'src/apiUrls/TmpCodeMasterApiUrls'
import TranslationText from './TranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
      CngSwitchField,

    }
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  NotificationType
} = constants

const DEFAULT_INITIAL_VALUES = ({
  codeType: "",
  name: "",
  status: "",
  code: "",
  description: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, mode }) {
  const translatedTextsObject = TranslationText();
  const [codeTypeOption, setCodeTypeOption] = useState([])
  const { fetchRecords } = useServices()

  useEffect(() => {
    getCodeTypeOptions();
  }, [])

  const getCodeTypeOptions = () => {
    const onSuccess = (response) => {
      const options = response.content.map((type) => {
        return { text: type.codeType, value: type.codeType }
      })
      setCodeTypeOption([...new Map(options.map(x => [x['text'], x])).values()]);
    }

    fetchRecords.execute(
      TmpCodeMasterApiUrls.GET,
      {},
      onSuccess,
      () => {
        showNotification(NotificationType.ERROR, "Failed to fetch Code Type options")
      }
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} >
                <CngTextField
                  name="code"
                  label={translatedTextsObject.code + " *"}
                  disabled={mode == "add" ? false : true}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} >
                <CngSwitchField
                  name="status"
                  label={"Active"}
                  disabled={disabled}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6}>
                <CngSelectField
                  name="codeType"
                  label={translatedTextsObject.codeType + " *"}
                  disabled={mode == "add" ? false : true}
                  items={codeTypeOption}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6}>
                <CngTextField
                  name="name"
                  label={translatedTextsObject.name + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6}>
                <CngTextField
                  name="description"
                  label={translatedTextsObject.description + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    </Grid>
  )
}


function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
