import React from 'react'
import { components } from 'cng-web-lib'

import TmpSupplierApiUrls from 'src/apiUrls/TmpSupplierApiUrls'
import FormProperties from './FormProperties'
import pathMap from 'src/paths/PathMap_TmpAdmin'

const {
  form: {
    CngAddForm
  }
} = components

function AddPage({ history, showNotification }) {
  return (

    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      shouldDisableEnterToSubmit
      showNotification={showNotification}
      history={history}
      bodySection={
        < FormProperties.Fields
          disabled={false}
          showNotification={showNotification}
          mode={"add"}
        />
      }
      formikProps={FormProperties.formikProps}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}

      create={{
        url: TmpSupplierApiUrls.CREATE,
        successRedirect: pathMap.SUPPLIER_LIST_VIEW
      }}
      innerForm={true}
    />
  )

}


export default AddPage
