import React from 'react';
import { constants, useTranslation } from 'cng-web-lib'

import Namespace from "../../../constants/locale/Namespace";
import TmpComponentKeys from 'src/constants/locale/key/TmpComponent'

const NAMESPACE = Namespace.TMP_COMPONENT;

const {
    locale: {
        key: {
            UiComponentKeys,
        },
    },
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_TITLE);

    const number = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_NO)
    const name = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_NAME)
    const customTariff = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_CUSTOM_TARIFF)
    const uom = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_UOM)
    const material = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_MATERIAL_TYPE)
    const oriCountry = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_ORI_CTRY)
    const ltsd = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_LTSD)
    const ltsdStart = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_LTSD_START)
    const ltsdEnd = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_LTSD_END)
    const standardPrice = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_STANDARD_PRICE)
    const supplier = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_SUPPLIER)
    const createSupplier = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_CREATE_SUPPLIER)


    let tmpComponent = translate(NAMESPACE, TmpComponentKeys.TMP_COMPONENT_TITLE)


    return {
        moduleTitle,
        number,
        name,
        customTariff,
        uom,
        material,
        oriCountry,
        ltsd,
        ltsdStart,
        ltsdEnd,
        standardPrice,
        supplier,
        tmpComponent,
        createSupplier
    }
}

export default TranslationText;


