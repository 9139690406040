import React from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import TranslationText from './TranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    },
    field: {
      CngTextField,
      CngLookupAutocompleteField

    }
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  NotificationType
} = constants

const DEFAULT_INITIAL_VALUES = ({
  name: "",
  registerNo: "",
  referenceNo: "",
  address: "",

})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, mode }) {
  const translatedTextsObject = TranslationText();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="name"
                  label={translatedTextsObject.name + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="registerNo"
                  label={translatedTextsObject.registerNo}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="referenceNo"
                  label={translatedTextsObject.referenceNo}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="address"
                  label={translatedTextsObject.address}
                  disabled={disabled}
                  multiline={true}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    </Grid>
  )
}


function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
