import { Yup, constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants
const today = new Date();
today.setHours(0, 0, 0, 0);
function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )


  return Yup.object(
    {
      name: Yup.string().required(requiredMessage),
    })
}

export default makeValidationSchema
