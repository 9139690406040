import React from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from '../../../constants/locale/Namespace'
import TmpSysConfigKeys from '../../../constants/locale/key/TmpSysConfig'
import makeValidationSchema from '../system-config/MakeValidationSchema'


const {
    form: {field: { CngTextField } },
    CngGridItem,
} = components

// Field Default Values
const DEFAULT_VALUE = Object.freeze({
    groupName: "",
    name: "",
    displayName: "",
    value: "",
    description:"",
    remarks: "",
    version: ""
})

const FORMIK_PROPS = {
    initialValues: {...DEFAULT_VALUE},
    makeValidationSchema: makeValidationSchema
}

function Fields({disabled, showNotification, shouldHideMap, id}) {
    const {translate} = useTranslation(Namespace.TMP_SYS_CONFIG)
    const translatedTextsObject = makeTranslatedTextsObject()

    //* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let groupName = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_GROUP)
		let displayName = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_DISPLAY_NAME)
		let remarks = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_REMARK)
        let configCode = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_CONFIG_CODE)
        let value = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_VALUE)
        let description = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_DESCRIPTION)
        let version = "version"

		return {
			groupName,
			configCode,
			displayName,
			value,
			description,
			remarks,
            version
		}
    }

        //* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={3}>

							<CngGridItem xs={12} shouldHide={shouldHideMap.groupName}>
								<CngTextField name="groupName"
									label={translatedTextsObject.groupName}
									disabled={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.name}>
								<CngTextField name="name"
									label={translatedTextsObject.configCode}
									disabled={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.displayName}>
								<CngTextField name="displayName"
									label={translatedTextsObject.displayName}
									disabled={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.value}>
								<CngTextField name="value"
									label={translatedTextsObject.value}
									disabled={disabled}
									multiline={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.description}>
								<CngTextField name="description"
									label={translatedTextsObject.description}
									multiline={true}
									disabled={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.remarks}>
								<CngTextField name="remarks"
									label={translatedTextsObject.remarks}
									disabled={disabled}
									multiline={true}
								/>
							</CngGridItem>
                           
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------
	)
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties


