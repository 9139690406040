import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import TmpSysConfigKey from 'src/constants/locale/key/TmpSysConfig'
import BreadcrumbNameMap from '../../../navigations/breadcrumbs/Breadcrumb_TmpADMIN'
import TablePage from './TablePage'
import EditPage from './EditPage'
import ViewPage from './ViewPage'

//* Module Title
function ModuleTitle() {
    const { translate } = useTranslation(Namespace.TMP_SYS_CONFIG)
    let title = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKey.TMP_SYS_CONFIG_TITLE)

    return (title);
}

//* Wrapped Table Page
function WrappedTablePage(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <TablePage showNotification = {showSnackbar} {...props}/>
        )}
            extraCngBreadcrumbNameMap = {breadcrumbNameMap}
            ModuleTitle={ModuleTitle()}
        />
    )
}

//* Wrap Edit Page
function WrappedEditPage(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <EditPage showNotification={showSnackbar} {...props}/>
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            ModuleTitle={ModuleTitle()}
        />
    )
}

//* Wrap View Page
function WrappedViewPage(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <ViewPage showNotification={showSnackbar} {...props}/>
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            ModuleTitle={ModuleTitle()}
        />
    )
}

export {
    WrappedTablePage as TablePage,
    WrappedViewPage as ViewPage,
    WrappedEditPage as EditPage
}