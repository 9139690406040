import React from 'react';
import { constants, useTranslation } from 'cng-web-lib'

import Namespace from "../../../constants/locale/Namespace";
import TmpSupplierKeys from 'src/constants/locale/key/TmpSupplier'

const NAMESPACE = Namespace.TMP_SUPPLIER;

const {
    locale: {
        key: {
            UiComponentKeys,
        },
    },
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, TmpSupplierKeys.TMP_SUPPLIER_TITLE);

    const name = translate(NAMESPACE, TmpSupplierKeys.TMP_SUPPLIER_NAME)

    let tmpSupplier = translate(NAMESPACE, TmpSupplierKeys.TMP_SUPPLIER_TITLE)


    return {
        moduleTitle,
        name,
        tmpSupplier
    }
}

export default TranslationText;


