import React, { useState, useEffect } from 'react'
import {
  Grid,
  Card,
  CardContent,
  IconButton
} from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form"
import makeValidationSchema from './MakeValidationSchema'
import TranslationText from './TranslationText'
import TmpCodeMasterApiUrls from 'src/apiUrls/TmpCodeMasterApiUrls'
import TmpSupplierApiUrls from 'src/apiUrls/TmpSupplierApiUrls'
import TmpCountryApiUrls from 'src/apiUrls/TmpCountryApiUrls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreateSupplierDialog from './CreateSupplierDialog.js'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import CountryAutocompleteField from 'src/components/autocomplete/CountryAutocompleteField'


const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    },
    field: {
      CngTextField,
      CngLookupAutocompleteField,
      CngDateField,
      CngSwitchField
    }
  },
  CngGridItem
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = ({
  componentNumber: "",
  componentName: "",
  customTariffNumber: "",
  uom: "",
  materialType: "",
  oriCountry: "",
  ltsd: false,
  ltsdStartDate: "",
  ltsdEndDate: "",
  standardPricePerUom: "",
  supplierManufactureName: "",
  oriCountryCode: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, mode }) {
  const form = useFormContext()
  const translatedTextsObject = TranslationText();
  const ltsdValue = useWatch({ name: 'ltsd' });
  const supplierValue = useWatch({ name: 'supplierManufactureName' });
  const { setFieldValue } = useFormikContext()
  const [ltsdOption, setltsdOption] = useState([])
  const [refreshSupplier, setRefreshSupplier] = useState(0)

  useEffect(() => {
    // This is refresh ltsdOption
  }, [ltsdOption])

  const handleRefreshSupplier = () => setRefreshSupplier(refreshSupplier + 1)

  const handleLtsdChange = (e) => {
    form.setValue('ltsd', e.target.checked)

    if (ltsdValue == true) {
      setFieldValue('ltsdStartDate', '')
      setFieldValue('ltsdEndDate', '')
      if (supplierValue == '') {
        setFieldValue('supplierManufactureName', '')
      }
      form.clearErrors(['ltsdStartDate', 'ltsdEndDate', 'supplierManufactureName'])
    }
  }

  const [isDialogOpen, setDialogOpen] = useState(false)

  function closeDialog() {
    setRefreshSupplier(refreshSupplier + 1)
    setDialogOpen(false)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="componentNumber"
                  label={translatedTextsObject.number + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="componentName"
                  label={translatedTextsObject.name + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="customTariffNumber"
                  label={translatedTextsObject.customTariff + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
            </Grid>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                  name="uom"
                  label={translatedTextsObject.uom}
                  disabled={disabled}
                  lookupProps={{
                    url: TmpCodeMasterApiUrls.GET,
                    label: c => `(${c.code}) ${c.name}`,
                    value: 'code',
                    filters: [
                      {
                        field: 'codeType',
                        operator: EQUAL,
                        value: 'TMP_COMPONENT_UOM'
                      },
                      {
                        field: 'status',
                        operator: EQUAL,
                        value: true
                      }
                    ]
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="standardPricePerUom"
                  label={translatedTextsObject.standardPrice}
                  disabled={disabled}
                  type='number'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                  name="materialType"
                  label={translatedTextsObject.material}
                  disabled={disabled}
                  lookupProps={{
                    url: TmpCodeMasterApiUrls.GET,
                    label: 'name',
                    value: 'id',
                    filters: [
                      {
                        field: 'codeType',
                        operator: EQUAL,
                        value: 'TMP_COMPONENT_MATERIAL'
                      },
                      {
                        field: 'status',
                        operator: EQUAL,
                        value: true
                      }
                    ]
                  }}
                />
              </CngGridItem>
            </Grid>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                {/* <CngLookupAutocompleteField
                  name="oriCountry"
                  label={translatedTextsObject.oriCountry}
                  disabled={disabled}
                  lookupProps={{
                    url: TmpCountryApiUrls.GET,
                    label: 'name',
                    value: 'id',
                    filters: []
                  }}
                /> */}
                <CountryAutocompleteField
                  label={translatedTextsObject.oriCountry}
                  name='oriCountryCode'
                  accessor='countryList'
                  disabled={disabled}
                  url={TradeMgmtApiUrls.GET_TMP_COUNTRY}
                  lookupProps={{
                    label: 'name',
                    value: 'code',
                    dataAccessor: 'content',
                    url: TradeMgmtApiUrls.GET_TMP_COUNTRY
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                  key={refreshSupplier}
                  name="supplierManufactureName"
                  label={translatedTextsObject.supplier}
                  disabled={disabled}
                  lookupProps={{
                    url: TmpSupplierApiUrls.GET,
                    label: 'name',
                    value: 'id'
                  }}
                />

                <div>
                  <a
                    href="#"
                    onClick={() => {
                      setDialogOpen(true)
                    }}>
                    <FontAwesomeIcon icon={['fal', 'plus']} size={'xs'} /> {translatedTextsObject.createSupplier}
                  </a>
                  &emsp;
                  <IconButton size='small'
                    onClick={handleRefreshSupplier}>
                    <FontAwesomeIcon icon={['fal', 'arrows-rotate']} />
                  </IconButton>
                </div>
              </CngGridItem>

            </Grid>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngSwitchField
                  name="ltsd"
                  label={translatedTextsObject.ltsd}
                  onChange={handleLtsdChange}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngDateField
                  name="ltsdStartDate"
                  label={translatedTextsObject.ltsdStart + " *"}
                  disabled={ltsdValue == true ? false : true}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngDateField
                  name="ltsdEndDate"
                  label={translatedTextsObject.ltsdEnd + " *"}
                  disabled={ltsdValue == true ? false : true}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <CreateSupplierDialog
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        showNotification={showNotification} />
    </Grid>
  )
}


function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
