import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  DataFlattener
} from 'cng-web-lib'

import TmpComponentApiUrls from 'src/apiUrls/TmpComponentApiUrls'
import TmpCodeMasterApiUrls from 'src/apiUrls/TmpCodeMasterApiUrls'
import TmpSupplierApiUrls from 'src/apiUrls/TmpSupplierApiUrls'
import TmpCountryApiUrls from 'src/apiUrls/TmpCountryApiUrls'
import React, { useRef } from 'react'
import TranslationText from './TranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup

  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
} = constants

function TablePage(props,
  orderBy,
  orderDirection) {
  console.log('Component table page')
  const {
    location: { pathname },
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const translatedTextsObject = TranslationText();
  const fetchCustomLookup = useFetchCustomLookup();
  const cngTableRef = useRef()

  const columns = [
    {
      field: "componentNumber",
      title: translatedTextsObject.number
    },
    {
      field: "componentName",
      title: translatedTextsObject.name,
      defaultSort: 'asc'
    },
    {
      field: "customTariffNumber",
      title: translatedTextsObject.customTariff
    },
    {
      field: "uom",
      title: translatedTextsObject.uom,
      customLookup: () => {
        return fetchCustomLookup(
          `${TmpCodeMasterApiUrls.GET}`,
          {
            "codeType": "TMP_COMPONENT_UOM",
            "status": true
          },
          'content',
          'name', // label accessor
          'code', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "materialType",
      title: translatedTextsObject.material,
      customLookup: () => {
        return fetchCustomLookup(
          `${TmpCodeMasterApiUrls.GET}`,
          {
            "codeType": "TMP_COMPONENT_MATERIAL",
            "status": true
          },
          'content',
          'name', // label accessor
          'id', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "oriCountry",
      title: translatedTextsObject.oriCountry,
      customLookup: () => {
        return fetchCustomLookup(
          `${TmpCountryApiUrls.GET}`,
          {},
          'content',
          'name', // label accessor
          'id', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "supplierManufactureName",
      title: translatedTextsObject.supplier,
      customLookup: () => {
        return fetchCustomLookup(
          `${TmpSupplierApiUrls.GET}`,
          {
          },
          'content',
          'name', // label accessor
          'id', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "ltsd",
      title: translatedTextsObject.ltsd,
      type: "boolean",
      // filtering: false
    },
    {
      field: "ltsdStartDate",
      title: translatedTextsObject.ltsdStart,
      type: 'datetime',
      filtering: false,
      hidden: true
    },
    {
      field: "ltsdEndDate",
      title: translatedTextsObject.ltsdEnd,
      type: 'datetime',
      filtering: false,
      hidden: true
    },
    {
      field: "standardPricePerUom",
      title: translatedTextsObject.standardPrice
    },

  ]


  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              idAccessor="id"
              notification={notification}
              addRoute={`${pathname}/add`}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              del={{ url: TmpComponentApiUrls.DELETE }}
              fetch={{ url: TmpComponentApiUrls.GET }}
              cngTaviewRoutebleRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

function toServerDataFormat(localData) {
  const reqData = {
    "data": [{
      "componentNumber": localData.componentNumber,
      "componentName": localData.componentName,
      "customTariffNumber": localData.customTariffNumber,
      "uom": localData.uom,
      "materialType": localData.materialType,
      "oriCountry": localData.oriCountry,
      "ltsd": localData.ltsd,
      "ltsdStartDate": localData.ltsdStartDate,
      "ltsdEndDate": localData.ltsdEndDate,
      "standardPricePerUom": localData.standardPricePerUom,
      "supplierManufactureName": localData.supplierManufactureName
    }]
  }
  return DataFlattener.unflatten(reqData);
}

export default TablePage
