import React from 'react'
import { components } from 'cng-web-lib'

import TmpCompanyProfileApiUrls from 'src/apiUrls/TmpCompanyProfileApiUrls'
import FormProperties from './FormProperties'

const {
  form: {
    CngAddForm
  }
} = components

function AddPage({ history, showNotification, onPostSubmitSuccess }) {
  return (

    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      shouldDisableEnterToSubmit
      showNotification={showNotification}
      history={history}
      bodySection={
        < FormProperties.Fields
          disabled={false}
          showNotification={showNotification}
          mode={"add"}
        />
      }
      formikProps={FormProperties.formikProps}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}

      create={{
        url: TmpCompanyProfileApiUrls.CREATE,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
      innerForm={true}
    />
  )

}


export default AddPage
