import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  DataFlattener,
  useServices
} from 'cng-web-lib'

import TmpCodeMasterApiUrls from 'src/apiUrls/TmpCodeMasterApiUrls'
import React, { useRef } from 'react'
import TranslationText from './TranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup

  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const translatedTextsObject = TranslationText();
  const fetchCustomLookup = useFetchCustomLookup();
  const cngTableRef = useRef()
  const { deleteRecord } = useServices()

  const columns = [
    {
      field: "codeType",
      title: translatedTextsObject.codeType,
      customLookup: () => {
        return fetchCustomLookup(
          TmpCodeMasterApiUrls.GET,
          {},
          'content',
          'codeType',
          'codeType',
          (error) => {
            console.log(error)
          }
        )
      }
    },
    {
      field: "code",
      title: translatedTextsObject.code,
      // defaultSort: "asc",
    },
    {
      field: "name",
      title: translatedTextsObject.name
    },
    {
      field: "description",
      title: translatedTextsObject.description
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      type: "boolean"
    },
    {
      field: "createdDate",
      title: "",
      defaultSort: "desc",
      type: 'datetime',
      filtering: false,
      hidden: true
    }
  ]


  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              idAccessor="id"
              notification={notification}
              addRoute={`${pathname}/add`}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              del={{ url: TmpCodeMasterApiUrls.DELETE }}
              // deleteButtonProps={{
              //   onClick: (e, rowData) => {
              //     deleteRecord.execute(TmpCodeMasterApiUrls.DELETE,
              //       toServerDataFormat(rowData),
              //       (data) => {
              //         showNotification("success", "Record deleted successfully")
              //         if (cngTableRef.current.performRefresh) {
              //           cngTableRef.current.performRefresh()
              //         }
              //       },
              //       (error) => {
              //         console.log(error)
              //       }
              //     )
              //   }
              // }
              // }
              fetch={{ url: TmpCodeMasterApiUrls.GET }}
              cngTaviewRoutebleRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

function toServerDataFormat(localData) {
  const reqData = {
    "codeType": localData.codeType,
    "data": [{
      "code": localData.code,
      "status": localData.status === true ? 'Active' : 'Inactive',
      "name": localData.name,
      "description": localData.description
    }]
  }
  return DataFlattener.unflatten(reqData);
}

export default TablePage
