import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import MassUpload from './MassUpload.js'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_TmpADMIN'
import TranslationText from './TranslationText'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
  const translatedTextsObject = TranslationText();
  const moduleTitle = translatedTextsObject.moduleTitle;
  return moduleTitle;
}

function WrappedUploadPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <MassUpload showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}


export {
  WrappedUploadPage as UploadPage,
}
