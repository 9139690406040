import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from '../../../constants/locale/Namespace'
import TmpSysConfigApiUrls from '../../../apiUrls/tmp-admin/TmpSysConfigApiUrls'
import TmpSysConfigKeys from '../../../constants/locale/key/TmpSysConfig'


const {
    card: {CngSimpleCardHeader},
    table: { CngCrudTable, useDefaultNotification }
} = components
const { locale: {key: {UiComponentKeys}}} = constants;

function TablePage(props) {
    const { location: {pathname}, showNotification} = props
    const notification = useDefaultNotification(showNotification)
    const { translate } = useTranslation([
        Namespace.UI_COMPONENT, Namespace.TMP_SYS_CONFIG
    ])
    const translatedTextsObject = makeTranslatedTextsObject();

    function makeTranslatedTextsObject() {
        let title = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_TITLE)

        let tableTitle = translate(
            Namespace.UI_COMPONENT,
            UiComponentKeys.Table.TITLE,
            { nameTitleised: title}
        )

        //columns
        let group = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_GROUP)
        let configCode = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_CONFIG_CODE)
        let value = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_VALUE)
        let description = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_DESCRIPTION)

        return {
            tableTitle,
            group,
            configCode,
            value,
            description
        }
    }

        const columns = [
            {
            field: 'groupName', title: translatedTextsObject.group,
            lookup: {
                "AEB API Settings": "AEB API Settings"
            }
            },
            { field: 'name', title: translatedTextsObject.configCode },
            { field: 'value', title: translatedTextsObject.value },
            { field: 'description', title: translatedTextsObject.description}
        ];

        //return JSX
        return (
            <Card>
                <CngSimpleCardHeader title={translatedTextsObject.tableTitle}/>
                <Divider/>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{wordBreak: "break-word"}}>
                            <CngCrudTable {...props}
                            notification = {notification}

                            //table fetch and data details
                            fieldLevel = 'toBeChangedIfWantFieldLevel'
                            fetch = {{ url: TmpSysConfigApiUrls.GET }}
                            idAccessor = 'id'
                            columns = {columns}

                            // view route
                            viewRoute={`${pathname}/view`}

                            // edit route
                            editRoute={`${pathname}/edit`}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            //END JSX
        );
}

export default TablePage