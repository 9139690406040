import { Grid, Box, CircularProgress, Button, Card, CardContent, } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import TmpCodeMasterApiUrls from 'src/apiUrls/TmpCodeMasterApiUrls'
import Paper from '@material-ui/core/Paper';
import * as Yup from 'yup'
import { useFormContext } from 'react-hook-form'
import { Download } from 'react-feather'
import makeValidationSchema from './MakeValidationSchema'
import TranslationText from './TranslationText'


const {
    dropzone: { CngFileUpload, HelperTextArea },
    form: {
        adapter: {
            useFormAdapter: { useField }
        },
        field: {
            CngLookupAutocompleteField
        },
        CngForm
    },
    button: { CngPrimaryButton },
    CngGridItem,
    card: { CngSimpleCardHeader },
} = components
const { FormState, NotificationType } = constants

const {
    filter: { EQUAL },
    TransactionType
} = constants

const DEFAULT_INITIAL_VALUES = ({
    transactionType: ""
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function MassUpload({ showNotification, taskStatus }) {
    const [successMsg, setSuccessMsg] = useState('')
    const { securedSendRequest } = useServices()
    const [traxType, setTraxType] = useState('')

    // new------
    const [shouldShowPrimaryButtonCircularProgress, setShouldShowPrimaryButtonCircularProgress] = useState(false)
    const [errorMsgs, setErrorMsgs] = useState([]);
    const validationSchema = Yup.object({})

    const translatedTextsObject = TranslationText();

    function handleDisableUpload() {
        if (traxType === '') {
            return true
        }
    }

    function onSubmit(data) {
        setShouldShowPrimaryButtonCircularProgress(true)
        setSuccessMsg('')
        setErrorMsgs([])


        return new Promise((resolve) => {
            if (data && data.files.length > 0) {
                const formData = new FormData()
                formData.append('fileContents', data.files[0].file)
                formData.append('transactionType', traxType)
                let offsetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                formData.append('timezone', offsetTimeZone)


                securedSendRequest.execute('POST', TradeMgmtApiUrls.MASS_UPLOAD, formData,
                    (response) => {
                        setShouldShowPrimaryButtonCircularProgress(false)
                        setSuccessMsg(response.data.status)
                        resolve()
                    },
                    (error) => {
                        setShouldShowPrimaryButtonCircularProgress(false)
                        setErrorMsgs(error.response.data.errorMessages);
                        resolve()

                    },
                    () => {
                        resolve()
                    }
                )
            } else {
                setShouldShowPrimaryButtonCircularProgress(false)
                resolve()
            }
        })
    }
    // new --------

    return (
        <Paper >
            <CngForm
                formikProps={{
                    initialValues: {
                        files: []
                    },
                    validationSchema: validationSchema,
                    onSubmit: onSubmit
                }}
                renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                    <>
                        <Fields
                            labelMap={labelMap}
                            shouldHideMap={shouldHideMap}
                            shouldDisableMap={shouldDisableMap}
                            showNotification={showNotification}
                            errorMsgs={errorMsgs}
                            setErrorMsgs={setErrorMsgs}
                            translatedTextsObject={translatedTextsObject}
                            successMsg={successMsg}
                            setSuccessMsg={setSuccessMsg}
                            taskStatus={taskStatus}
                            handleDisableUpload={handleDisableUpload}
                            traxType={traxType}
                            setTraxType={setTraxType}
                        />
                    </>
                )}
                renderButtonSection={() => (
                    <SubmitButton
                        shouldShowPrimaryButtonCircularProgress={shouldShowPrimaryButtonCircularProgress}
                        setShouldShowPrimaryButtonCircularProgress={setShouldShowPrimaryButtonCircularProgress}
                        showNotification={showNotification}
                        onSubmit={onSubmit}
                        handleDisableUpload={handleDisableUpload}
                        translatedTextsObject={translatedTextsObject}
                        taskStatus={taskStatus}
                    />
                )}
                formState={FormState.COMPLETED}
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            />
        </Paper>

    )




}

const SubmitButton = ({ disabled, shouldShowPrimaryButtonCircularProgress, onSubmit, handleDisableUpload, taskStatus, translatedTextsObject }) => {
    const {
        handleSubmit
    } = useFormContext()

    const handleDisable = handleDisableUpload(taskStatus)

    return (
        <Box p={5} style={{ textAlign: 'center' }}>
            <CngPrimaryButton
                type='submit'
                disabled={handleDisable}
                onClick={handleSubmit(onSubmit)}
                endIcon={
                    shouldShowPrimaryButtonCircularProgress ? (
                        <CircularProgress color='inherit' size={10} />
                    ) : null
                }
            >
                {translatedTextsObject.uploadButton}
            </CngPrimaryButton>
        </Box >
    );
}



function Fields({ disabled, showNotification, errorMsgs, setErrorMsgs, successMsg, setSuccessMsg, taskStatus, handleDisableUpload, traxType, setTraxType, translatedTextsObject }) {
    const [uploadFile, , { setValue: setUploadFile }] = useField('files')
    const moreActions = [
        {
            action: 'remove',
            name: 'Remove',
            icon: ['fal', 'trash']
        }
    ]


    const handleDisable = handleDisableUpload(traxType)

    const downloadTemplateClickHandler = () => {
        let fileName = '';
        let filePath = '';
        console.log(traxType);
        if (traxType === undefined) {
            showNotification(NotificationType.ERROR, "Transaction Type is Mandatory. Please select a transaction Type.")
        } else if (traxType === 'SUPP') {
            fileName = 'SUPPLIER.xlsx'
        } else if (traxType === 'PRIN') {
            fileName = 'PRINCIPAL_PROFILE.xlsx'
        } else if (traxType === 'COMP') {
            fileName = 'COMPONENT.xlsx'
        } else if (traxType === 'PROD') {
            fileName = 'MY_PRODUCT.xlsx'
        } else {
            showNotification(NotificationType.ERROR, "Transaction Type not supported. Please contact System Admin.")
        }
        filePath = `${process.env.PUBLIC_URL}/static/tmp/${fileName}`
        if (fileName !== '' && filePath !== '') {
            let a = document.createElement('a')
            a.href = filePath
            a.setAttribute('download', fileName)
            a.click()
        }

    }



    useEffect(() => {
        if (uploadFile.value.length == 0 && errorMsgs.length > 0 || uploadFile.value.length == 0 && successMsg.length > 0) {
            setErrorMsgs([])
            setSuccessMsg('')
        }
    }, [uploadFile])



    return (
        <Card>
            <CardContent>
                < Grid container spacing={3} >
                    <CngGridItem xs={12} sm={12}>
                        <CngLookupAutocompleteField
                            name="transactionType"
                            label="Master data upload"
                            required
                            disabled={disabled}
                            lookupProps={{
                                url: TmpCodeMasterApiUrls.GET,
                                label: c => `${c.name}`,
                                value: 'code',
                                filters: [
                                    {
                                        field: 'codeType',
                                        operator: EQUAL,
                                        value: 'TMP_TRANSACTION_TYPE'
                                    },
                                    {
                                        field: 'status',
                                        operator: EQUAL,
                                        value: true
                                    }
                                ]
                            }}
                            onChange={(val) => {
                                setTraxType(val);
                            }}
                        />
                        <Box style={{ float: 'right' }} pr={5} pt={2} >
                            <Button
                                variant='contained'
                                color='secondary'
                                disabled={handleDisable}
                                startIcon={<Download style={{ marginRight: 5 }} />}
                                classes={{ root: 'ng-button-filled-secondary' }}
                                onClick={() => { downloadTemplateClickHandler() }}
                            >
                                {translatedTextsObject.downloadTemplate}
                            </Button>
                        </Box>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={12}>
                        <Box pr={5} pl={5}>
                            <Box alignItems='center' pt={1} >
                                <CngFileUpload
                                    disabled={handleDisableUpload(taskStatus)}
                                    accept={['.xls', '.xlsx']}
                                    maxFiles={1}
                                    maxSize={10485760}
                                    onFileSelect={(files) => {
                                        setUploadFile(files)
                                    }}
                                    files={uploadFile.value}
                                    showFormFields={false}
                                    moreActions={moreActions}
                                    onDropRejected={(errorMessages) => {
                                        showNotification(NotificationType.ERROR, errorMessages[0])
                                    }}
                                    renderHelperText={() => {
                                        return (
                                            <HelperTextArea
                                                accept={['.xls', '.xlsx']}
                                                maxFiles={1}
                                                maxSize={1048576}
                                            />
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                    </CngGridItem>
                    {errorMsgs && errorMsgs.length > 0 &&
                        < CngGridItem xs={12} sm={12}>
                            <Box pr={5} pl={5}>
                                <Box alignItems='center'>
                                    {errorMsgs.map((msg) => (
                                        <p key={msg} style={{ color: '#FF808B' }}>
                                            {msg}
                                        </p>
                                    ))}
                                </Box>
                            </Box>
                        </CngGridItem>
                    }
                    {successMsg && successMsg.length > 0 &&
                        < CngGridItem xs={12} sm={12}>
                            <Box pr={5} pl={5}>
                                <Box alignItems='center'>
                                    <p style={{ color: '#33CC33' }}>
                                        {successMsg}
                                    </p>
                                </Box>
                            </Box>
                        </CngGridItem>

                    }
                </Grid >
            </CardContent>
        </Card>
    )
}


export default MassUpload