import React from 'react';
import { constants, useTranslation } from 'cng-web-lib'

import Namespace from "../../../constants/locale/Namespace";
import TmpCodeMasterKeys from 'src/constants/locale/key/TmpCodeMaster'

const NAMESPACE = Namespace.TMP_CODE_MASTER;

const {
    locale: {
        key: {
            UiComponentKeys,
        },
    },
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, TmpCodeMasterKeys.TMP_CODE_MASTER);

    const codeType = translate(NAMESPACE, TmpCodeMasterKeys.TMP_CODE_MASTER_CODE_TYPE)
    const name = translate(NAMESPACE, TmpCodeMasterKeys.TMP_CODE_MASTER_NAME)
    const status = translate(NAMESPACE, TmpCodeMasterKeys.TMP_CODE_MASTER_STATUS)
    const code = translate(NAMESPACE, TmpCodeMasterKeys.TMP_CODE_MASTER_CODE)
    const description = translate(NAMESPACE, TmpCodeMasterKeys.TMP_CODE_MASTER_DESCRIPTION)

    let tmpCodeMaster = translate(NAMESPACE, TmpCodeMasterKeys.TMP_CODE_MASTER)


    return {
        moduleTitle,
        codeType,
        name,
        status,
        code,
        description,
        tmpCodeMaster
    }
}

export default TranslationText;


